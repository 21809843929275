<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Evaluación de riesgos antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarEvaluacionRiesgo)">
                            <b-row>
                                <b-col md="3">
                                    <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Fecha:" class="mb-2">
                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model.lazy="datosNuevaEvaluacion.fecha"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- <b-col md="6">
                                    <b-form-group label="Documentación:" class="mb-2">
                                        <b-input-group>
                                            <b-input-group-prepend v-if="datosNuevaEvaluacion.urlDocumentacion != ''">
                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosNuevaEvaluacion.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                            <b-form-file ref="file" v-model.lazy="datosNuevaEvaluacion.documentacion" v-on:change="handleFileUpload" :placeholder="datosNuevaEvaluacion.nombreDocumentacion ? datosNuevaEvaluacion.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosNuevaEvaluacion.nombreDocumentacion ? datosNuevaEvaluacion.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>

                                        </b-input-group>
                                    </b-form-group>
                                </b-col> -->
                                <!-- <b-col md="12" v-if="datosNuevaEvaluacion.idEvaluacion!=''">
                                    <validation-provider name="motivo de revisión" :rules="{}" v-slot="validationContext">
                                        <b-form-group label="Motivo de revisión:" class="mb-2">
                                            <b-form-textarea v-model.lazy="datosNuevaEvaluacion.motivoRevision" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese motivo de revisión"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col> -->
                            </b-row>

                            <!-- <b-col class="mt-3" md="12">
                                <span class="h6 text-muted">POR FAVOR, AGREGUE LOS RIESGOS QUE DESEA EVALUAR</span>
                                <CButton size="sm" class="float-right" color="dark" @click="modalAgregarRiesgos = true">
                                    <i class="fas fa-plus fa-sm"></i> Agregar riesgos
                                </CButton>
                            </b-col> -->
                            <b-col class="mt-3">
                                <hr>
                            </b-col>
                            <b-col md="12" class="mt-3">
                                <template>
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 " :class="datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual>0 && datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual<=6 ? 'bg-accordion-success' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual>6 && datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual<16 ? 'bg-accordion-warning' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual>=16 ? 'bg-accordion-danger' : 'bg-accordion'" role="tab">
                                                <div block v-b-toggle="'accordion-'+datosNuevaEvaluacion.datosAcordionRiesgo.idRiesgo" :class="datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual>0 && datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual<=6 ? 'bg-accordion-success' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual>6 && datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual<16 ? 'bg-accordion-warning' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual>=16 ? 'bg-accordion-danger' : 'bg-accordion'">
                                                    <span class=" h6"> {{datosNuevaEvaluacion.datosAcordionRiesgo.nombre}}</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse visible :id="'accordion-'+datosNuevaEvaluacion.datosAcordionRiesgo.idRiesgo" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="12" class="mt-2">
                                                            <b-table :items="datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones.filter(x => x.estado == '2')" :fields="cabeceraGestiones" class="table-custom" mediun responsive outlined fixed>
                                                                <template v-slot:cell(gestiones)="row">
                                                                    <b-row>
                                                                        <b-col md="3">
                                                                            <b-form-group label="Fecha de gestión:" class="mb-2">
                                                                                <b-form-input v-model.lazy="row.item.fechaGestion" type="date"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <!-- <b-col md="7">
                                                                            <b-form-group label="Documentación:" class="mb-2">
                                                                                <b-input-group>
                                                                                    <b-input-group-prepend v-if="row.item.urlDocumentacion != ''">
                                                                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                                                            <i class="fas fa-download fa-xs"></i>
                                                                                        </b-button>
                                                                                    </b-input-group-prepend>
                                                                                    <b-form-file :ref="`file-${row.index}`" v-on:change="handleFileUpload($event, row)" :placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir" v-model.lazy="row.item.documentacion"></b-form-file>

                                                                                </b-input-group>
                                                                            </b-form-group>
                                                                        </b-col> -->
                                                                        <b-col md="2" class="text-right mt-3">
                                                                            <b-button @click="quitarGestion(row)" v-if="datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones.filter(x => x.estado == '2').length >= 2" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar gestión
                                                                            </b-button>
                                                                            <!-- <b-button @click="eliminarGestion(row, k)" v-if="datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones.length >= 2 && row.item.idEvaluacionDetalleGestion!=''" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                               <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar gestión
                                                                            </b-button> -->
                                                                        </b-col>
                                                                        <b-col md="12" class="mt-2">
                                                                            <span class="h6 text-muted">Medidas a implantar</span>
                                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarMedidaImplantar(row)">
                                                                                <i class="fas fa-plus fa-sm"></i> Agregar medida
                                                                            </CButton>
                                                                        </b-col>
                                                                        <b-col md="12" class="mt-2">
                                                                            <b-table :items="row.item.listaMedidas.filter(x => x.estado == '2')" :fields="cabeceraMedidas" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                                                                <template #table-colgroup="cabeceraMedidas">
                                                                                    <col v-for="field in cabeceraMedidas.fields" :key="field.key" :style="{ width: field.key === 'responsable' ? '25%' : field.key === 'accionesTexto' ? '25%' : field.key === 'evidencias' ? '30%' : '10%' }">
                                                                                </template>
                                                                                <template v-slot:cell(responsable)="rowMedidas">
                                                                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model.lazy="rowMedidas.item.idPuestoTrabajo" :options="comboCargoResponsable">
                                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                                                    </v-select>
                                                                                    <v-select :reduce="comboResponsable =>comboResponsable.idEmpleado" class="mt-2" label="nombres" placeholder="Seleccione una opción" v-model.lazy="rowMedidas.item.idEmpleado" :options="comboResponsable">
                                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Recursos humanos'}"> Registra aquí</router-link></span>
                                                                                    </v-select>
                                                                                </template>
                                                                                <template v-slot:cell(accionesTexto)="rowMedidas">
                                                                                    <b-form-textarea v-model.lazy="rowMedidas.item.accionesTexto" placeholder="Ingrese las acciones" rows="3" max-rows="6"></b-form-textarea>
                                                                                </template>
                                                                                <template v-slot:cell(evidencias)="rowMedidas">
                                                                                    <b-form-textarea v-model.lazy="rowMedidas.item.evidencias" placeholder="Ingrese las evidencias" rows="3" max-rows="6"></b-form-textarea>
                                                                                </template>
                                                                                <template v-slot:cell(fechaProgramada)="rowMedidas">
                                                                                    <b-form-input type="date" v-model.lazy="rowMedidas.item.fechaProgramada"></b-form-input>
                                                                                </template>
                                                                                <!-- <template v-slot:cell(plazo)="rowMedidas">
                                                                                    <b-form-select v-model.lazy="rowMedidas.item.plazo" :options="comboPlazo" value-field="idPlazo" text-field="descripcion">
                                                                                        <template v-slot:first>
                                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                        </template>
                                                                                    </b-form-select>
                                                                                </template>
                                                                                <template v-slot:cell(ejecutada)="rowMedidas">
                                                                                    <b-form-checkbox plain :id="'checkbox-'+k+'-'+row.index+'-'+rowMedidas.index" v-model.lazy="rowMedidas.item.ejecutada" :name="'checkbox-'+k+'-'+row.index+'-'+rowMedidas.index" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                </template> -->
                                                                                <template #cell(acciones)="param">
                                                                                    <b-button @click="quitarMedidaImplantar(param, row)" v-if="datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones[row.index].listaMedidas.filter(x => x.estado == '2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                    </b-button>
                                                                                    <!-- <b-button @click="quitarMedidaImplantar(param, row, k)" v-if="datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones[row.index].listaMedidas.length >= 2 && param.item.idEvaluacionDetalleMedida!=''" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                    </b-button> -->
                                                                                </template>
                                                                            </b-table>
                                                                        </b-col>
                                                                    </b-row>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                        <b-col md="12" class="text-center my-2">
                                                            <CButton size="sm" color="dark" @click="agregarGestion()">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar gestión
                                                            </CButton>
                                                        </b-col>
                                                        <b-col md="12" class="my-2">
                                                            <span class="h6">Primera evaluación:</span>
                                                        </b-col>
                                                        <b-col md="12" class="my-2">
                                                            <span>Controles iniciales:</span>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="control" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Control:" class="mb-2">
                                                                    <v-select disabled @input="obtenerSubControles" :reduce="comboControles =>comboControles.idControlAntisoborno" label="descripcionControl" placeholder="Seleccione una opción" v-model="datosNuevaEvaluacion.datosAcordionRiesgo.idControl" :options="comboControles">
                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="subcontroles" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Sub controles:" class="mb-2">
                                                                    <v-select disabled multiple :reduce="comboSubControles =>comboSubControles.idSubControl" label="subControl" placeholder="Seleccione una opción" v-model="datosNuevaEvaluacion.datosAcordionRiesgo.idSubControl" :options="comboSubControles">
                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'probabilidad inicial de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre">
                                                                <b-form-group label="Probabilidad inicial:" class="mb-2">
                                                                    <b-form-select class="disabled-white" disabled v-model="datosNuevaEvaluacion.datosAcordionRiesgo.probabilidad" :options="listaProbabilidad">
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'impacto inicial de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre">
                                                                <b-form-group label="Impacto inicial:" class="mb-2">
                                                                    <b-form-select class="disabled-white" disabled v-model="datosNuevaEvaluacion.datosAcordionRiesgo.impacto" :options="listaImpacto">
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <b-form-group label="Tipo inicial:" class="mb-2">
                                                                <b-input-group>
                                                                    <b-input-group-prepend>
                                                                        <b-input-group-text :style="`background:${datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto == 'Alto' ? '#e55353;' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto == 'Medio' ? '#f9b115;' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto == 'Bajo' ? '#2eb85c;' : '' }`">
                                                                        </b-input-group-text>
                                                                    </b-input-group-prepend>
                                                                    <b-form-input class="disabled-white" disabled v-model="datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto" placeholder=""></b-form-input>
                                                                </b-input-group>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <hr>
                                                        </b-col>
                                                        <b-col md="12" class="my-2">
                                                            <span class="h6">Reevaluación:</span>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <validation-provider :name="'efectos de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre" v-slot="validationContext">
                                                                <b-form-group label="Efectos:" class="mb-2">
                                                                    <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese los efectos" v-model.lazy="datosNuevaEvaluacion.datosAcordionRiesgo.efectos" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" class="my-2">
                                                            <span>Controles actuales:</span>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="control" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Control:" class="mb-2">
                                                                    <v-select @input="obtenerSubControlesActual" :reduce="comboControles =>comboControles.idControlAntisoborno" label="descripcionControl" placeholder="Seleccione una opción" v-model="datosNuevaEvaluacion.datosAcordionRiesgo.idControlActual" :options="comboControles">
                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="subcontroles" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Sub controles:" class="mb-2">
                                                                    <v-select multiple :reduce="comboSubControles =>comboSubControles.idSubControl" label="subControl" placeholder="Seleccione una opción" v-model="datosNuevaEvaluacion.datosAcordionRiesgo.idSubControlActual" :options="comboSubControlesActual">
                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'probabilidad actual de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre">
                                                                <b-form-group label="Probabilidad actual:" class="mb-2">
                                                                    <b-form-select v-on:change="calcularTipoRiesgoActual()" v-model="datosNuevaEvaluacion.datosAcordionRiesgo.probabilidadActual" :options="listaProbabilidad">
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'impacto actual de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre">
                                                                <b-form-group label="Impacto actual:" class="mb-2">
                                                                    <b-form-select v-on:change="calcularTipoRiesgoActual()" v-model="datosNuevaEvaluacion.datosAcordionRiesgo.impactoActual" :options="listaImpacto">
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <b-form-group label="Tipo actual:" class="mb-2">
                                                                <b-input-group>
                                                                    <b-input-group-prepend>
                                                                        <b-input-group-text :style="`background:${datosNuevaEvaluacion.datosAcordionRiesgo.tipoTextoActual == 'Alto' ? '#e55353;' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoTextoActual == 'Medio' ? '#f9b115;' : datosNuevaEvaluacion.datosAcordionRiesgo.tipoTextoActual == 'Bajo' ? '#2eb85c;' : '' }`">
                                                                        </b-input-group-text>
                                                                    </b-input-group-prepend>
                                                                    <b-form-input class="disabled-white" disabled v-model="datosNuevaEvaluacion.datosAcordionRiesgo.tipoTextoActual" placeholder=""></b-form-input>
                                                                </b-input-group>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'fecha de seguimiento de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre" :rules="{ required: true }" v-slot="validationContext">
                                                                <b-form-group label="Fecha de seguimiento:" class="mb-2">
                                                                    <b-form-input v-model.lazy="datosNuevaEvaluacion.datosAcordionRiesgo.fechaSeguimiento" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'clasificación SIG de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre" v-slot="validationContext">
                                                                <b-form-group label="Clasificación SIG:" class="mb-2">
                                                                    <b-form-input placeholder="Ingrese la clasificación SIG" v-model.lazy="datosNuevaEvaluacion.datosAcordionRiesgo.clasificacionSig" :state="getValidationState(validationContext)"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4">
                                                            <validation-provider :name="'fue eficaz de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre" v-slot="validationContext">
                                                                <b-form-group label="Fue eficaz:" class="mb-2">
                                                                    <b-form-radio-group plain v-model.lazy="datosNuevaEvaluacion.datosAcordionRiesgo.fueEficaz" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]" :state="getValidationState(validationContext)"></b-form-radio-group>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <validation-provider :name="'descripción u observación de '+datosNuevaEvaluacion.datosAcordionRiesgo.nombre" v-slot="validationContext">
                                                                <b-form-group label="Descripción u observación:" class="mb-2">
                                                                    <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese la descripción u observación" v-model.lazy="datosNuevaEvaluacion.datosAcordionRiesgo.descripcionObservacion" :state="getValidationState(validationContext)"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </template>
                            </b-col>
                            <b-col class="my-2 text-center" md="12">
                                <!-- <b-button class="mr-2" :to="{name: 'Selección riesgos'}" variant="dark">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button> -->
                                <b-button v-if="$route.params.id!=null && datosNuevaEvaluacion.idRiesgo!=''" :to="{name: 'Ver evaluaciones de riesgos antisoborno', params: {id: datosNuevaEvaluacion.idRiesgo}}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="$route.params.id==null" :to="{name: 'Selección riesgos antisoborno'}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="checkPermissions('017-37001-ERA','c') == 1 && $route.params.id==null" :disabled="disabled" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-save"></i> Guardar
                                </b-button>
                                <b-button v-if="checkPermissions('017-37001-ERA','u') == 1 && $route.params.id!=null" :disabled="disabled" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-save"></i> Guardar
                                </b-button>

                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalAgregarRiesgos: false,
            BtnMostrar: false,
            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "riesgo",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "selected",
                    label: "Selección",
                    class: "text-center",
                }
            ],
            cabeceraGestiones: [{
                key: 'gestiones',
                label: 'Gestiones',
            }],
            cabeceraMedidas: [{
                    key: 'responsable',
                    label: 'Responsable',
                    class: 'text-center'
                }, {
                    key: 'accionesTexto',
                    label: 'Acciones',
                    class: 'text-center'
                }, {
                    key: 'evidencias',
                    label: 'Evidencias',
                    class: 'text-center'
                },
                {
                    key: 'fechaProgramada',
                    label: 'Fecha programada',
                    class: 'text-center'
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center'
                }
            ],
            listaRiesgos: [],

            arrayRiesgos: [],

            datosNuevaEvaluacion: {
                idEvaluacion: '',
                idCliente: '',
                fecha: moment().format('YYYY-MM-DD'),
                idRiesgo: '',
                datosAcordionRiesgo: {
                    idRiesgo: '',
                    nombre: '',
                    idControl: '',
                    idSubControl: '',
                    probabilidad: '',
                    impacto: '',
                    tipo: '',
                    tipoTexto: '',
                    fechaSeguimiento: moment().format('YYYY-MM-DD'),
                    clasificacionSig: '',
                    fueEficaz: 2,
                    descripcionObservacion: '',
                    efectos: '',
                    idControlActual: '',
                    idSubControlActual: '',
                    probabilidadActual: null,
                    impactoActual: null,
                    tipoActual: '',
                    tipoTextoActual: '',
                    listaGestiones: [{
                        fechaGestion: moment().format('YYYY-MM-DD'),
                        listaMedidas: [{
                            idPuestoTrabajo: '',
                            idEmpleado: '',
                            accionesTexto: '',
                            evidencias: '',
                            fechaProgramada: moment().format('YYYY-MM-DD'),
                            estado: 2,
                        }],
                        /* documentacion: null,
                        urlDocumentacion: '',
                        nombreDocumentacion: '', */
                        estado: 2,
                    }],
                },
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            listaProbabilidad: [{
                    value: 1,
                    text: 'Seria excepcional'
                },
                {
                    value: 2,
                    text: 'Es raro que suceda'
                },
                {
                    value: 3,
                    text: 'Es posible'
                },
                {
                    value: 4,
                    text: 'Muy probable'
                },
            ],
            listaImpacto: [{
                    value: 1,
                    text: 'Insignificante'
                },
                {
                    value: 2,
                    text: 'Pequeño'
                },
                {
                    value: 3,
                    text: 'Moderado'
                },
                {
                    value: 4,
                    text: 'Grande'
                },
                {
                    value: 5,
                    text: 'Catastrófico'
                },
            ],
            comboCargoResponsable: [],
            comboResponsable: [],
            disabled: false,
            datosEvaluacionDetalleGestion: [],
            datosEvaluacionDetalleMedida: [],
            comboControles: [],
            comboSubControles: [],
            comboSubControlesActual: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        calcularTipoRiesgo() {
            let me = this;
            let probabilidad = me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidad != '' ? parseFloat(me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidad) : 0;
            let impacto = me.datosNuevaEvaluacion.datosAcordionRiesgo.impacto != '' ? parseFloat(me.datosNuevaEvaluacion.datosAcordionRiesgo.impacto) : 0;
            let result = probabilidad * impacto;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo = result;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto = result >= 0 && result <= 6 ? 'Bajo' : result > 6 && result < 16 ? 'Medio' : result >= 16 ? 'Alto' : '';
        },
        calcularTipoRiesgoActual() {
            let me = this;
            let probabilidadActual = me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidadActual != '' ? parseFloat(me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidadActual) : 0;
            let impactoActual = me.datosNuevaEvaluacion.datosAcordionRiesgo.impactoActual != '' ? parseFloat(me.datosNuevaEvaluacion.datosAcordionRiesgo.impactoActual) : 0;
            let result = probabilidadActual * impactoActual;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual = result;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoTextoActual = result >= 0 && result <= 6 ? 'Bajo' : result > 6 && result < 16 ? 'Medio' : result >= 16 ? 'Alto' : '';
        },
        obtenerSubControles(e) {
            this.datosNuevaEvaluacion.datosAcordionRiesgo.idSubControl = [];
            let subControles = JSON.parse(this.comboControles.find(x => x.idControlAntisoborno == e).listaControlesDetalle);
            this.comboSubControles = subControles;
        },
        obtenerSubControlesActual(e) {
            this.datosNuevaEvaluacion.datosAcordionRiesgo.idSubControlActual = [];
            let subControles = JSON.parse(this.comboControles.find(x => x.idControlAntisoborno == e).listaControlesDetalle);
            this.comboSubControlesActual = subControles;
        },
        handleFileUpload(e, row) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file-${row.index}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`file-${row.index}`].reset();
                    return;
                }
            }
        },
        blurPuestoTrabajo() {
            this.computedForm.refs.cargo.validate();
        },
        listarControlesAntisoborno() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-controles-antisoborno-sga", {
                        params: {
                            idCliente: me.datosNuevaEvaluacion.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    for (const i in response.data) {
                        me.comboControles.push({
                            idControlAntisoborno: response.data[i].idControlAntisoborno,
                            descripcionControl: response.data[i].numeroControl + ' - ' + response.data[i].descripcionControl,
                            listaControlesDetalle: response.data[i].listaControlesDetalle,
                        })
                    }
                    if (me.$route.params.arrayRiesgos != null && me.$route.params.id == null) {
                        me.agregarRiesgos()
                    } else if (me.$route.params.id != null) {
                        me.consultarEvaluacionRiesgo();
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        agregarRiesgos() {
            let me = this;
            let arrayRiesgos = me.$route.params.arrayRiesgos;

            me.datosNuevaEvaluacion.idRiesgo = arrayRiesgos[0].idRiesgo;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.idRiesgo = arrayRiesgos[0].idRiesgo;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.nombre = arrayRiesgos[0].nombre;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.idControl = arrayRiesgos[0].idControl;
            me.obtenerSubControles(me.datosNuevaEvaluacion.datosAcordionRiesgo.idControl);
            me.datosNuevaEvaluacion.datosAcordionRiesgo.idSubControl = JSON.parse(arrayRiesgos[0].idSubControl);
            me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidad = arrayRiesgos[0].probabilidad;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.impacto = arrayRiesgos[0].impacto;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo = arrayRiesgos[0].tipo;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto = me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo >= 0 && me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo <= 6 ? 'Bajo' : me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo > 6 && me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo < 16 ? 'Medio' : me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo >= 16 ? 'Alto' : '';

        },
        consultarEvaluacionRiesgo() {
            let me = this;
            let arrayRiesgos = me.$route.params.arrayRiesgos;
            /* me.datosNuevaEvaluacion.datosAcordionRiesgo = arrayRiesgos; */

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-evaluacion-riesgo-antisoborno-sga", {
                        params: {
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {

                    let datosAcordion = JSON.parse(response.data[0].datosAcordionRiesgo);
                    //console.log(datosAcordion)
                    me.datosNuevaEvaluacion.idEvaluacion = response.data[0].idEvaluacion;
                    me.datosNuevaEvaluacion.idCliente = response.data[0].idCliente;
                    me.datosNuevaEvaluacion.fecha = response.data[0].fecha;
                    me.datosNuevaEvaluacion.idRiesgo = response.data[0].idRiesgo;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.idRiesgo = response.data[0].idRiesgo;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.nombre = response.data[0].nombre;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.idControl = response.data[0].idControl;
                    me.obtenerSubControles(me.datosNuevaEvaluacion.datosAcordionRiesgo.idControl);
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.idSubControl = JSON.parse(response.data[0].idSubControl);
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidad = response.data[0].probabilidad;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.impacto = response.data[0].impacto;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo = response.data[0].tipo;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoTexto = me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo >= 0 && me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo <= 6 ? 'Bajo' : me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo > 6 && me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo < 16 ? 'Medio' : me.datosNuevaEvaluacion.datosAcordionRiesgo.tipo >= 16 ? 'Alto' : '';

                    me.datosNuevaEvaluacion.datosAcordionRiesgo.fechaSeguimiento = datosAcordion.fechaSeguimiento;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.clasificacionSig = datosAcordion.clasificacionSig;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.fueEficaz = datosAcordion.fueEficaz;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.descripcionObservacion = datosAcordion.descripcionObservacion;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.efectos = datosAcordion.efectos;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.idControlActual = datosAcordion.idControlActual;
                    me.obtenerSubControlesActual(me.datosNuevaEvaluacion.datosAcordionRiesgo.idControlActual);
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.idSubControlActual = datosAcordion.idSubControlActual;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.probabilidadActual = datosAcordion.probabilidadActual;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.impactoActual = datosAcordion.impactoActual;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual = datosAcordion.tipoActual;
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoTextoActual = me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual >= 0 && me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual <= 6 ? 'Bajo' : me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual > 6 && me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual < 16 ? 'Medio' : me.datosNuevaEvaluacion.datosAcordionRiesgo.tipoActual >= 16 ? 'Alto' : '';
                    me.datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones = datosAcordion.listaGestiones;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosNuevaEvaluacion.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosNuevaEvaluacion.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarEvaluacionRiesgo() {
            let me = this;

            const formData = new FormData();
            formData.append("datosEvaluacion", JSON.stringify(me.datosNuevaEvaluacion));

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-riesgo-antisoborno-sga",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.$router.push({
                        name: 'Evaluación de riesgos antisoborno',
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        agregarMedidaImplantar(row) {
            let me = this;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones[row.index].listaMedidas.push({
                idEvaluacionDetalleMedida: '',
                idEvaluacionDetalleGestion: '',
                idPuestoTrabajo: '',
                idEmpleado: '',
                accionesTexto: '',
                evidencias: '',
                fechaProgramada: moment().format('YYYY-MM-DD'),
                estado: 2,
            })
        },
        quitarMedidaImplantar(param, row) {
            let me = this;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones[row.index].listaMedidas.splice(param.index, 1);
        },
        agregarGestion() {
            let me = this;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones.push({
                idEvaluacionDetalleGestion: '',
                idEvaluacionDetalleRiesgo: '',
                fechaGestion: moment().format('YYYY-MM-DD'),
                listaMedidas: [{
                    idEvaluacionDetalleMedida: '',
                    idEvaluacionDetalleGestion: '',
                    idPuestoTrabajo: '',
                    idEmpleado: '',
                    accionesTexto: '',
                    evidencias: '',
                    fechaProgramada: moment().format('YYYY-MM-DD'),
                    estado: 2,
                }],
                /* documentacion: null,
                urlDocumentacion: '',
                nombreDocumentacion: '', */
                estado: 2,
            })
        },
        quitarGestion(row) {
            let me = this;
            me.datosNuevaEvaluacion.datosAcordionRiesgo.listaGestiones.splice(row.index, 1);
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosNuevaEvaluacion.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            this.listarEmpleados();
            this.listarControlesAntisoborno();
            /* if (this.$route.params.arrayRiesgos != null && this.$route.params.id == null) {
                //this.agregarRiesgos();

            } else if (this.$route.params.arrayRiesgos != null && this.$route.params.id != null) {
                this.consultarEvaluacionDetalleGestion();
                this.consultarEvaluacionRiesgo();
                //this.consultarEvaluacionDetalleMedida();
            } */
        }

    }

}
</script>
